
export function isNameValid(form) {
  const errorEl = document.querySelector('#name ~ .error');

  if (!form.name) {
    errorEl.textContent = 'Name is required';
    return false;
  }

  if (form.name.length > 100) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  errorEl.textContent = '';
  return true;
}

export function isEmailValid(form) {
  const errorEl = document.querySelector('#email ~ .error');

  if (!form.email) {
    errorEl.textContent = 'Email is required';
    return false;
  }

  if (form.email.length > 100) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  if (!form.email.toLowerCase().match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
    errorEl.textContent = 'Email is invalid';
    return false;
  }

  errorEl.textContent = '';
  return true;
}

export function isCompanyValid(form) {
  const errorEl = document.querySelector('#company ~ .error');

  if (form.company?.length > 100) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  errorEl.textContent = '';
  return true;
}

export function isNumberValid(form) {
  const errorEl = document.querySelector('#contactNumber ~ .error');

  if (form.contactNumber?.length > 30) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  errorEl.textContent = '';
  return true;
}

export function isMsgValid(form) {
  const errorEl = document.querySelector('#message ~ .error');

  if (form.message?.length > 1000) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  errorEl.textContent = '';
  return true;
}

export function isAboutValid(form) {
  const errorEl = document.querySelector('#about ~ .error');

  if (form.about?.length > 1000) {
    errorEl.textContent = 'Maxlength exceeded';
    return false;
  }

  errorEl.textContent = '';
  return true;
}
