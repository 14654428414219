export class CubeVideo {
  nativeElement;

  get playing() {
    const videoEl = this.nativeElement;
    return (videoEl && videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > 2);
  }

  constructor(element) {
    this.nativeElement = element;
    this.nativeElement.muted = true;
    this.load();
  }

  play() {
    if (this.playing) {
      return;
    }
    try {
      this.nativeElement.play();
    } catch (e) {
      console.log('play error', e);
    }
  }

  pause() {
    try {
      this.nativeElement.pause();
    } catch (e) {
      console.log('pause error', e);
    }
  }

  load() {
    try {
      if (!this.nativeElement.readyState || this.nativeElement.readyState < 3) {
        this.nativeElement.load();
      }
    } catch (e) {
      console.log('load error', e);
    }
  }
}
