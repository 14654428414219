import { BehaviorSubject, combineLatest, filter, take  } from 'rxjs';

import { loaderService } from '../../js/loader-service';

class CubeService {

  #cubeBoxEl;

  #stopCubeDelayTimer;

  #cubeActive$ = new BehaviorSubject(false);

  get cubeActive$(){
    return this.#cubeActive$.asObservable();
  }

  #cubeVisible$ = new BehaviorSubject(false);

  #intersectionObserver;

  constructor() {
    this.#intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (this.#cubeVisible$.getValue() !== entry.isIntersecting) {
          this.#cubeVisible$.next(entry.isIntersecting);
        }
      });
    });
  }

  onDestroy() {
    this.#intersectionObserver.disconnect();
    clearTimeout(this.#stopCubeDelayTimer);
  }

  setCube(cubeBoxEl) {
    return loaderService.isLoaded$
        .pipe(
            filter(isLoaded => isLoaded),
            take(1)
        )
        .subscribe(() => {
          this.#cubeBoxEl = cubeBoxEl;
          this.startCube();

          this.#intersectionObserver.observe(this.#cubeBoxEl);

          this.switchOnOffCube();
        });
  }

  startCube() {
    clearTimeout(this.#stopCubeDelayTimer);
    if (!this.#cubeActive$.getValue()) {
      this.#cubeActive$.next(true);
    }
  }

  stopCubeDelayed() {
    clearTimeout(this.#stopCubeDelayTimer);
    this.#stopCubeDelayTimer = setTimeout(() => {
      if (this.#cubeActive$.getValue()) {
        this.#cubeActive$.next(false);
      }
    }, 500);
  }

  switchOnOffCube() {
    return this.#cubeVisible$.subscribe((isCubeVisible) => {
      if (isCubeVisible) {
        this.startCube();
      } else {
        this.stopCubeDelayed();
      }
    });
  }
}

export const cubeService = new CubeService();
