export function registerSplashBtns(btns) {
  btns.forEach((btn) => addITagToSplashBtn(btn));
}

export function onDestroySplashBtns(btns) {
  if (!btns || !btns.length) {
    return;
  }

  btns.forEach((btn) => {
    btn.removeEventListener('mouseover', splashBtnMouseover);
    btn.removeEventListener('mouseout', splashBtnMouseout);
  });
}

function addITagToSplashBtn(btn) {
  const btnHtml = btn.innerHTML;
  const span = document.createElement('span');
  span.className = 'btn-content';
  span.innerHTML = btnHtml;
  btn.innerHTML = '';
  btn.appendChild(span);

  const iElement = document.createElement('i');

  btn.appendChild(iElement);

  const color = btn.dataset.color;

  if (color) {
    iElement.style.background = color;
  }

  const duration = btn.dataset.duration;

  if (duration) {
    iElement.style.transitionDuration = duration;
  }

  const textColor = btn.dataset.textColor;

  if (textColor) {
    btn.classList.add('color-change');
  }

  btn.addEventListener('mouseover', (event) => splashBtnMouseover(event, textColor));

  btn.addEventListener('mouseout', (event) => splashBtnMouseout(event, textColor));
}

function splashBtnMouseover(event, textColor) {
  calculateSplashPosition(event);
  event.target.classList.add('active');
  if (textColor) {
    event.target.style.color = textColor;
  }
}

function splashBtnMouseout(event, textColor) {
  calculateSplashPosition(event);
  event.target.classList.remove('active');

  if (textColor) {
    event.target.style.color = '';
  }
}

function calculateSplashPosition(event) {
  let x;
  let y;

  if (event instanceof MouseEvent) {
    x = event.clientX;
    y = event.clientY;
  } else if (event instanceof TouchEvent && event.touches?.length) {
    x = event.touches[0].clientX;
    y = event.touches[0].clientY;
  } else {
    return;
  }

  const buttonRect = event.target.closest('.splash-btn');
  const iElement = event.target.querySelector('i');
  iElement.style.left = x - buttonRect.x + 'px';
  iElement.style.top = y - buttonRect.y + 'px';
}
