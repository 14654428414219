import { Observable, fromEvent, BehaviorSubject } from 'rxjs';

function isContainerWindow(container) {
  return container === window;
}

export const TriggerPosition = {
  BEFORE_TRIGGER: 'before_trigger',
  IN_TRIGGER: 'in_trigger',
  AFTER_TRIGGER: 'after_trigger'
}

export const TriggerEvents = {
  ENTER: 'enter',
  TOGGLE: 'toggle',
  PROGRESS: 'progress'
}

export const TriggerType = {
  START: 'start',
  END: 'end',
  START_AFTER_START: 'start_after_start',
  END_AFTER_END: 'end_after_end'
}

class IntersectionObserveFabrica {
  intersectionObservers = [];

  addListener(params, el) {
    const existingObserve = this.intersectionObservers.find(({ params: { root, rootMargin, threshold }}) => {
      return params.root === root &&
        params.rootMargin === rootMargin &&
        ((
          Array.isArray(params.threshold) &&
          Array.isArray(threshold) &&
          JSON.stringify(params.threshold) === JSON.stringify(threshold)
        ) ||
        params.threshold === threshold)
    });

    if (existingObserve) {
      existingObserve.intersectionObserve.observe(el);

      existingObserve.elements.push(el);

      return { listener: existingObserve.listener, id: existingObserve.id };
    }

    const id = Math.random().toString(16).slice(2);

    const subject = new BehaviorSubject([]);

    const intersectionObserve = new IntersectionObserver((entries) => {
      subject.next(entries);
    }, {
      root: params.root,
      rootMargin: params.rootMargin,
      threshold: params.threshold
    });

    intersectionObserve.observe(el);

    this.intersectionObservers.push({
      id,
      params,
      intersectionObserve,
      elements: [el],
      listener: subject
    });

    return { listener: subject, id };
  }

  deleteListener(observeId, el) {
    const observe = this.intersectionObservers.find(({ id }) => id === observeId);

    if (!observe) {
      return;
    }

    observe.intersectionObserve.unobserve(el);

    observe.elements = observe.elements.filter((element) => element !== el);

    if (!observe.elements.length) {
      observe.intersectionObserve.disconnect();
    }
  }
}

class ScrollTrigger {
  id;

  horizontal = false;

  once = false;

  triggerPosition = TriggerPosition.BEFORE_TRIGGER;

  direction = 1;

  lastYCoord = 0;

  progress = false;

  scrollSubscription = null;

  elRect;

  maxPercent = 0;

  start;

  end;

  intersectionObserve;

  initialObserve;

  intersectionObserveSubscription;

  constructor(
    el,
    subscriber,
    container,
    options,
    intersectionObserveFabrica
  ) {
    this.id = Math.random().toString(16).slice(2);

    this.el = el;
    this.subscriber = subscriber;
    this.container = container;
    this.intersectionObserveFabrica = intersectionObserveFabrica;

    this.horizontal = options.horizontal || false;
    this.once = options.once || false;
    this.progress = options.progress || false;

    this.handleIntersectionObserver = this.handleIntersectionObserver.bind(this);

    ({
      start: this.start,
      end: this.end
    } = options);

    this.initialObserve = new IntersectionObserver(this.initIntersectionObserve.bind(this));

    this.initialObserve.observe(this.el);
  }

  initIntersectionObserve(entries) {
    if (!entries.length) {
      return;
    }

    this.initialObserve.disconnect();

    this.elRect = entries[0].boundingClientRect;

    let scrollMargins, elementThreshold, startElementTriggerPosition, startScrollTriggerPosition;

    if (this.start) {
      const startTriggerPosition = this.getTriggingPosition(this.start);
      ({ element: startElementTriggerPosition, scroller: startScrollTriggerPosition } = startTriggerPosition);

      scrollMargins = this.calculateScrollerMargins(startScrollTriggerPosition);
      elementThreshold = this.calculateElementThreshold(startElementTriggerPosition);
    }

    if (this.progress && (!this.end || !this.start)) {
      throw new Error('End is required when progress is queried')
    }

    let thresholdArray = null;

    if (this.progress) {
      thresholdArray = this.calculateThresholdArray(
        startScrollTriggerPosition,
        startElementTriggerPosition);
    }

    this.intersectionObserve = this.intersectionObserveFabrica.addListener({
      root: isContainerWindow(this.container.container) ? null : this.container.container,
      rootMargin: scrollMargins,
      threshold: thresholdArray ?? elementThreshold
    }, this.el);

    this.intersectionObserveSubscription = this.intersectionObserve.listener.subscribe(this.handleIntersectionObserver);
  }

  handleIntersectionObserver(entries) {
    if (!entries.length) {
      return;
    }

    const entry = entries.find((entry) => entry.target === this.el);

    if (!entry) {
      return;
    }

    this.elRect = entry.boundingClientRect;

    this.calcDirection();

    let event = null;

    if (this.horizontal && entry.intersectionRect.x <= 0 && entry.boundingClientRect.x < entry.intersectionRect.x) {
      return;
    }

    let lastTriggerPosition = this.triggerPosition;

    if (entry.isIntersecting && this.triggerPosition === TriggerPosition.BEFORE_TRIGGER) {
      event = TriggerEvents.ENTER;
      this.triggerPosition = TriggerPosition.IN_TRIGGER;
    }

    if (!entry.isIntersecting && this.triggerPosition === TriggerPosition.IN_TRIGGER && this.direction === 1) {
      event = TriggerEvents.TOGGLE;
      this.triggerPosition = TriggerPosition.BEFORE_TRIGGER;
    }

    if (!this.end) {
      return this.sendEvent(event);
    }

    if (!entry.isIntersecting && this.triggerPosition === TriggerPosition.IN_TRIGGER && this.direction === -1) {
      event = TriggerEvents.TOGGLE;
      this.triggerPosition = TriggerPosition.AFTER_TRIGGER;
    }

    if (entry.isIntersecting && this.triggerPosition === TriggerPosition.AFTER_TRIGGER) {
      event = TriggerEvents.TOGGLE;
      this.triggerPosition = TriggerPosition.IN_TRIGGER;
    }

    this.sendProgress(entry.intersectionRatio, lastTriggerPosition);
    this.sendEvent(event);
  }

  calcDirection() {
    this.direction = (this.elRect?.y || 0) > this.lastYCoord ? 1 : -1;
    this.lastYCoord = this.elRect?.y || 0;
  }

  sendProgress(intersectionRatio, lastTriggerPosition) {
    let isActive = this.triggerPosition === TriggerPosition.IN_TRIGGER ||
      lastTriggerPosition === TriggerPosition.IN_TRIGGER;

    if (this.progress && isActive) {
      let progress = intersectionRatio / this.maxPercent;

      this.postEvent({
        isActive: true,
        event: TriggerEvents.PROGRESS,
        progress,
        direction: this.direction,
        id: this.id
      });
    }
  }

  sendEvent(event) {
    if (!event) {
      return;
    }

    let isActive = this.triggerPosition === TriggerPosition.IN_TRIGGER;

    this.postEvent({
      isActive,
      event,
      id: this.id,
      direction: this.direction
    });

    if (this.once && isActive) {
      this.container.removeTrigger(this.id);
    }
  }

  postEvent(event) {
    this.subscriber.next(event);
  }

  getTriggingPosition(position) {
    const positionComponents = position.split(' ');
    const elementPosition = positionComponents[0];

    if (positionComponents.length < 2) {
      throw new Error(`${position} should contain both place on the element and a place on the scroller. Ex: 'top center'.`);
    }

    let patternForElement = `^(?<position>${this.horizontal ? 'left|right|center' : 'bottom|top|center'})(?<shift>(?<shiftSign>[+-])=(?<shiftValue>\\d+)(?<shiftDimensions>px|%))?$`;
    let matchesForElement = new RegExp(patternForElement).exec(elementPosition);

    if (!matchesForElement) {
      throw new Error(`${elementPosition} is incorrect element position. Ex: 'top center'.`);
    }

    const scrollerPosition = positionComponents[1];

    let patternForScroller = `^((?<position>${this.horizontal ? 'left|right|center' : 'bottom|top|center'})?(?<shiftSign>([+-])?=(?<shiftValue>\\d+)(?<shiftDimensions>px|%))?|(?<value>\\d+)(?<dimensions>px|%))$`;
    let matchesForScroller = new RegExp(patternForScroller).exec(scrollerPosition);

    if (!matchesForScroller) {
      throw new Error(`${scrollerPosition} is incorrect scroller position. Ex: 'top center'.`);
    }

    return {
      element: matchesForElement.groups,
      scroller: matchesForScroller.groups
    };
  }

  calculateScrollerMargins(scroller) {
    if (scroller.value) {
      if (scroller.dimensions === '%') {
        return '0px 0px ' + (parseInt(scroller.value) * -1) + '%' + ' 0px';
      }
      return scroller.value + (scroller.dimensions || '') + '0px 0px 0px';
    }

    switch(scroller.position) {
      case 'top':
        return '100% 0px -100% 0px';
      case 'right':
        return '0px 100% 0px -100%';
      case 'left':
      case 'bottom':
        return '0px';
      case 'center':
        if (this.horizontal) {
          return '0px -50% 0px 0px';
        }
        return '100% 0px -50% 0px';
    }

    return;
  }

  calculateElementThreshold(element) {
    let base = 0;

    switch(element.position) {
      case 'top':
      case 'left':
        base = 0;
        break;
      case 'bottom':
      case 'right':
        base = 1;
        break;
      case 'center':
        base = 0.5;
        break;
    }

    if (!element.shift) {
      return base;
    }

    let shift = parseInt(element.shiftSign + element.shiftValue);

    if (element.shiftDimensions === '%') {
      return base + shift / 100;
    }

    return base;
  }

  calculateScrollerPositionValue(scroller) {
    if (scroller.value) {
      if (this.horizontal) {
        return scroller.dimensions === '%' ? this.container.rect.width * +scroller.value / 100 : +scroller.value;
      } else {
        return scroller.dimensions === '%' ? this.container.rect.height * +scroller.value / 100 : +scroller.value;
      }
    }

    let base = 0;

    switch(scroller.position) {
      case 'top':
        base = this.container.rect.top;
        break;
      case 'left':
        base = this.container.rect.left;
        break;
      case 'bottom':
        base = this.container.rect.height;
        break;
      case 'center':
        base = (this.horizontal ? this.container.rect.width : this.container.rect.height) / 2;
        break;
      case 'right':
        base = this.container.rect.width;
        break;
    }

    return this.calculatePositionShift(base, scroller);
  }

  calculateElementPositionValue(element) {
    let base = 0;

    switch(element.position) {
      case 'top':
      case 'left':
        base = 0
        break;
      case 'bottom':
        base = this.elRect.height;
        break;
      case 'center':
        base = (this.horizontal ? this.elRect.width : this.elRect.height) / 2;
        break;
      case 'right':
        base = this.elRect.width;
        break;
    }

    return this.calculatePositionShift(base, element);
  }

  calculatePositionShift(base, element) {
    if (!element.shift) {
      return base;
    }

    let shift = parseInt(element.shiftSign + element.shiftValue);
    let coeff = element.shiftDimensions === '%' ? this.container.rect.height / 100 : 1;

    return base + shift * coeff;
  }

  calculateThresholdArray(
    startScrollTriggerPosition,
    startElementTriggerPosition
  ) {
    const { element: endElementTriggerPosition, scroller: endScrollTriggerPosition } = this.getTriggingPosition(this.end);

    const startScrollerPosition = this.calculateScrollerPositionValue(startScrollTriggerPosition);
    const endScrollerPosition = this.calculateScrollerPositionValue(endScrollTriggerPosition);

    const startElementPosition = this.calculateElementPositionValue(startElementTriggerPosition);
    const endElementPosition = this.calculateElementPositionValue(endElementTriggerPosition);

    const distanceToScroll = (startScrollerPosition - endScrollerPosition) - (startElementPosition - endElementPosition);

    this.maxPercent = Math.floor((distanceToScroll / this.elRect.height) * 100) / 100 ;
    this.maxPercent = this.maxPercent > 1 ? 1 : this.maxPercent;

    const threasholds = [];

    for (let i = 0; i <= 20; i++) {
      threasholds.push(Math.floor((i / 20) * this.maxPercent * 100) / 100);
    }

    return threasholds;
  }

  unsubscribe() {
    this.subscriber.complete();
    this.subscriber.unsubscribe();
    this.intersectionObserveFabrica.deleteListener(this.intersectionObserve.id, this.el);
    this.intersectionObserveSubscription.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    this.initialObserve.disconnect();
  }
}

class ScrollListener {
  triggers = [];

  scroll$;

  rect;

  constructor(container) {
    this.container = container;
    this.scroll$ = fromEvent(this.container, 'scroll');
    this.rect = isContainerWindow(this.container) ? {
      width: window.innerWidth,
      height: window.innerHeight,
      top: 0,
      left: 0
    } : this.container.getBoundingClientRect();
  }

  addTrigger(trigger) {
    this.triggers.push(trigger);
  }

  async removeTrigger(id) {
   this.triggers.forEach((trigger, i, arr) => {
      if (trigger.id === id) {
        trigger.unsubscribe();
      }
    });

    this.triggers = this.triggers.filter((el) => el.id !== id);
  }
}

class ScrollTriggerService {
  scrollListeners = [];

  intersectionObserveFabrica;

  constructor() {
    this.subscribeNewElement = this.subscribeNewElement.bind(this);
    this.intersectionObserveFabrica = new IntersectionObserveFabrica();
  }

  ngOnDestroy() {
    this.scrollListeners.forEach(({ triggers }) => {
      triggers.forEach(({ subscriber }) => subscriber.unsubscribe());
    });
  }

  resize() {}

  registerElement(el, options = {}) {
    const observe = new Observable((subscriber) => this.subscribeNewElement(subscriber, el, options));

    return observe;
  }

  subscribeNewElement(subscriber, el, options) {
    const { container = window, ...restOptions } = options;

    let scrollListener = this.scrollListeners.find((listener) => listener.container === container);

    if (!scrollListener) {
      scrollListener = new ScrollListener(container);
      this.scrollListeners.push(scrollListener);
    }

    const scrollTrigger = new ScrollTrigger(el, subscriber, scrollListener, restOptions, this.intersectionObserveFabrica);

    subscriber.add(() => scrollListener.removeTrigger(scrollTrigger.id));

    scrollListener.addTrigger(scrollTrigger);
  }
}

export const scrollTrigger = new ScrollTriggerService();
