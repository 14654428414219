import { filter, take, fromEvent, merge, map } from 'rxjs';
import { notificationService, NotificationType } from './notification-service';
import { cookieService, CookieTypes } from './cookie-service';
import { loaderService } from './loader-service';
import { loadFonts } from './fonts-loader';

/* -------------------------NOTIFICATIONS------------------------------- */

let timer;

function listenForNotifications() {
  let rootEl = document.getElementById('notification');
  let titleEl = document.getElementById('title');
  let descriptionEl = document.getElementById('description');

  notificationService.notification$
    .subscribe((notification) => {
      if (notification) {
        titleEl.textContent = notification.title;
        descriptionEl.textContent = notification.description;

        rootEl.classList.remove('success', 'error', 'hidden');

        switch(notification.type) {
          case NotificationType.Success:
            rootEl.classList.add('success');
            break;
          case NotificationType.Error:
            rootEl.classList.add('error');
            break;
        }

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          notificationService.hide();
        }, 3000);
      } else {
        rootEl.classList.add('hidden');
      }
    });
}

function setCurrentLanguage() {
  if (window.LANGUAGE === 'en' && !cookieService.check(CookieTypes.SelectedLocale)) {
    return;
  }

  const el = document.getElementById('langSelector');

  el.addEventListener('change', selectLanguage);
  el.classList.remove('hidden');
}

function selectLanguage(event) {
  const lang = event.target.value;
  cookieService.set(CookieTypes.SelectedLocale, lang);

  let pathName = window.location.pathname;

  if (window.LANGUAGE !== 'en') {
    pathName = window.location.pathname.split('/').slice(2).join('/');
  }

  const url = (lang !== 'en')
      ? `${window.location.origin}/${lang}${pathName}`
      : `${window.location.origin}/${pathName}`;

  window.location.assign(url);
}

function goToTop() {
  const btnToTop = document.getElementById('btnToTop');
  btnToTop.addEventListener('click', () => {
    scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
}

function setAcceptCookies() {
  const cookieModal = document.getElementById('cookieModal');
  const btnAcceptCookies = cookieModal.querySelector('#btnAcceptCookies');
  const btnCloseCookies = cookieModal.querySelector('#btnCloseCookies');

  if (!cookieService.check(CookieTypes.CookiesAccepted)) {
    btnCloseCookies.addEventListener('click', () => {
      cookieModal.classList.add('hidden');
    }, { once: true });

    btnAcceptCookies.addEventListener('click', () => {
      cookieService.set(CookieTypes.CookiesAccepted, 1, 7);
      cookieModal.classList.add('hidden');
    }, { once: true });

    cookieModal.classList.remove('hidden');
  }
}

loaderService.isLoaded$
  .pipe(
    filter(isLoaded => isLoaded),
    take(1))
  .subscribe(() => {
    document.body.classList.remove('loading');
    document.getElementById('loader').classList.add('hidden');
    setTimeout(setAcceptCookies, 10000);
  });

loadFonts();

addEventListener('DOMContentLoaded', () => {
  listenForNotifications();
  setCurrentLanguage();
  goToTop();
}, { once: true });

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
