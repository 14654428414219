import { scrollTrigger, TriggerEvents } from './scroll-trigger';

export function connectTextSplit() {
  const textSplitEls = document.querySelectorAll('.text-split');

  if (!textSplitEls.length) {
    return;
  }

  textSplitEls.forEach((el) => {
    const oldHtml = el.innerHTML;
    const spanOuter = document.createElement('span');
    spanOuter.className = 'text-split';

    const spanInner = document.createElement('span');
    spanInner.className = 'original-content no-visible';

    spanOuter.appendChild(spanInner);
    spanInner.innerHTML = oldHtml;
    el.innerHTML = '';
    el.appendChild(spanOuter);

    setupScrollTrigger(spanOuter, spanInner);
  });
}

function setupScrollTrigger(wrap, textBlock) {
  scrollTrigger.registerElement(textBlock, {
    start: 'top 20%',
    once: true
  })
    .subscribe((event) => {
      if (event.event === TriggerEvents.ENTER) {
        let wrappedTextTemplate = document.createElement('span');

        findAndSplitText(textBlock.childNodes, wrappedTextTemplate);

        wrap.removeChild(textBlock);
        wrap.appendChild(wrappedTextTemplate);
      }
    });
}

function findAndSplitText(nodes, parent, wordIndex = 0) {
  nodes.forEach((node) => {
    if (node.nodeType === 3) {
      wordIndex = splitWords(node.nodeValue || '', parent, wordIndex);
    } else if (node.nodeType === 1) {
      const origNode = node.cloneNode(false);
      wordIndex = findAndSplitText(node.childNodes, origNode, wordIndex);
      parent.appendChild(origNode);
    }
  });

  return wordIndex;
}

function splitWords(text, parent, wordIndex = 0) {
  text.trim().split(/\s+/gm).forEach((word) => {
    let spanParent = document.createElement('span');

    spanParent.classList.add('word-wrap-parent');
    spanParent.style.setProperty('--word-index', `${wordIndex}`);

    let spanChild = document.createElement('span');

    spanChild.classList.add('word-wrap-child');

    spanChild.appendChild(document.createTextNode(word));
    spanParent.appendChild(spanChild);
    parent.appendChild(spanParent);
    parent.appendChild(document.createTextNode(' '));

    wordIndex += 1;
  });

  return wordIndex;
}
