import { ReplaySubject } from 'rxjs';

export const NotificationType = {
  Success: 'success',
  Error: 'error'
};

class NotificationService {

  #notification$ = new ReplaySubject(1);

  get notification$() {
    return this.#notification$.asObservable();
  }

  constructor() {
  }

  show(notification) {
    this.#notification$.next(notification);
  }

  hide() {
    this.#notification$.next(null);
  }
}

export const notificationService = new NotificationService();
