import { filter, take, fromEvent, combineLatest, merge, of, map } from 'rxjs';
import { translates } from 'translates';

import { scrollTrigger, TriggerEvents } from '../../js/scroll-trigger';
// import { metaService } from '../../js/meta-service';
import { notificationService, NotificationType } from '../../js/notification-service';
import { loaderService }from '../../js/loader-service';
import { registerSplashBtns } from '../../js/splash-btn-service';
import { setTriggerOnGreySections } from '../../js/grey-section';
import { connectTextSplit } from '../../js/text-split';
import {
  isNameValid,
  isEmailValid,
  isCompanyValid,
  isNumberValid,
  isMsgValid
} from '../../js/validation';

import { cubeService } from './cube-service';
import { CubeVideo } from './cube-video';
import { markerClients } from './clients-data.js';

function isTouchDevice() {
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
}

//region -------------------------LOGOS-------------------------------

const INIT_LOGOS_SCROLL_VALUE = 700;

let scrollFirstRowFn = scrollLogos.bind(this, 1);
let scrollSecondRowFn = scrollLogos.bind(this, 2);
let manualScroll = false;
let logosFirstRowEl = null;
let logosSecondRowEl = null;

function initLogosScroll() {
  const logosEl = document.getElementById('logos');
  logosFirstRowEl = logosEl.querySelector('#logosFirstRow');
  logosSecondRowEl = logosEl.querySelector('#logosSecondRow');

  logosFirstRowEl.addEventListener('scroll', scrollFirstRowFn);
  logosSecondRowEl.addEventListener('scroll', scrollSecondRowFn);

  logosFirstRowEl.scrollLeft = (logosFirstRowEl.scrollWidth - logosFirstRowEl.offsetWidth) / 2;

  initLogosDrag(logosFirstRowEl, logosSecondRowEl);

  scrollTrigger.registerElement(logosEl, {
    start: 'top center',
    once: true
  })
    .subscribe((data) => {
      if (data.event === TriggerEvents.ENTER) {
        logosFirstRowEl.scrollBy({
          top: 0,
          left: INIT_LOGOS_SCROLL_VALUE,
          behavior: 'smooth'
        });
      }
    });
}

function scrollLogos(row = 1) {
  if (manualScroll) {
    manualScroll = false;
    return;
  }

  if (row === 1) {
    logosSecondRowEl.scrollLeft = (logosSecondRowEl.scrollWidth - logosSecondRowEl.offsetWidth) - logosFirstRowEl.scrollLeft;
  }

  if (row === 2) {
    logosFirstRowEl.scrollLeft = (logosFirstRowEl.scrollWidth - logosFirstRowEl.offsetWidth) - logosSecondRowEl.scrollLeft;
  }

  manualScroll = true;
}

function initLogosDrag() {
  if (isTouchDevice()) {
    return;
  }

  initDragForElement(logosFirstRowEl);
  initDragForElement(logosSecondRowEl);
}

function initDragForElement(el) {
  let startScroll = el.scrollLeft;
  let startX = 0;

  const onMouseMove = (e) => {
    el.scrollLeft = startScroll - (e.pageX - startX);
  }

  const onMouseUp = (e) => {
    document.removeEventListener('mousemove', onMouseMove);

    el.style.cursor = 'grab';
  };

  const onMouseDown = (e) => {
    startScroll = el.scrollLeft;
    startX = e.pageX;

    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('mouseup', onMouseUp, { once: true });

    el.style.cursor = 'grabbing';
  };

  el.addEventListener('mousedown', onMouseDown, false);
  el.style.cursor = 'grab';
}
//endregion
//region -------------------------CONTACT US--------------------------

const inititialFormValues = {
  companyType: 'business',
  projectType: 'notsure',
  budget: '5k',
  name: '',
  email: '',
  contactNumber: '',
  company: '',
  message: '',
  attachment: '',
  file: ''
};

let formEl, attachmentWrap, attachment, submitBtn, removeBtn, file;
let form = {...inititialFormValues};
let isSubmitting = false;

function handleContactUs() {
  formEl = document.getElementById('contact_us_form');
  attachmentWrap = formEl.querySelector('#attachment-wrap');
  attachment = formEl.querySelector('#attachment');
  submitBtn = formEl.querySelector('#submitBtn');
  removeBtn = formEl.querySelector('#removeBtn');
  file = formEl.querySelector('#file');

  submitBtn.addEventListener('click', onSubmit);
  removeBtn.addEventListener('click', removeAttachment);
  file.addEventListener('change', fileLoaded);

  resetForm();
}

function removeAttachment(e) {
  e.stopPropagation();
  e.preventDefault();
  form.file = null;
  attachment.value = '';
  file.value = '';
  attachmentWrap.classList.add('hidden');
}

function fileLoaded(event) {
  if (event.target.files[0]) {
    form.file = event.target.files[0];
    attachmentWrap.classList.remove('hidden');
    attachment.value = event.target.files[0].name;
  }
}

function onSubmit() {
  if (isSubmitting) {
    return;
  }

  let body = new FormData(formEl);

  form = formDataToObject(body);

  if (!isValid()) {
    return;
  }

  sendRequest(body);
}

function formDataToObject(formData) {
  const formElemKeys = Array.from(formData.keys());

  return Object.fromEntries(
    formElemKeys.map(key => [key, formData.getAll(key)[0]])
  );
}

function isValid() {
  return isNameValid(form) &&
    isEmailValid(form) &&
    isCompanyValid(form) &&
    isNumberValid(form) &&
    isMsgValid(form);
}

async function sendRequest(body) {
  isSubmitting = true;

  try {
    const res = await fetch(`${window.API_URL}/contact`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body
    });

    isSubmitting = false;

    if (!res.ok) {
      throw new Error(res.message || res.statusText);
    }

    resetForm();

    notificationService.show({
      title: translates.notifications.contactUsForm.success.title,
      description: translates.notifications.contactUsForm.success.description,
      type: NotificationType.Success
    });
  } catch (err) {
    notificationService.show({
      title: translates.notifications.contactUsForm.error.title,
      description: translates.notifications.contactUsForm.error.description,
      type: NotificationType.Error
    });
  }
}

function resetForm() {
  formEl.reset();
  form = {...inititialFormValues};
  attachmentWrap.classList.add('hidden');
  for (let key in form) {
    const el = document.querySelector(`.contact-us [name="${key}"]`);
    if (el) {
      el.value = form[key];
    }
  }
}

function goToContactUs() {
  let contactUsBtn = document.getElementById('contact-us-btn');
  contactUsBtn.addEventListener('click', () => {
    window.location.href = '#contact-us-section';
  });
}
//endregion
//region -------------------------CUBE DESKTOP------------------------

class CubeDesktop {
  cubeBoxEl;
  cubeFrameEl;
  cubeWrapEl;
  cubePosterEl;

  loadingProgress = 0;
  loaded = false;
  active = false;
  firstStart = true;

  onPosterLoad = (() => {
    loaderService.setIsCubeReady();

    this.cubePosterEl.removeEventListener('load', this.onPosterLoad);
    this.cubePosterEl.removeEventListener('error', this.onPosterLoad);
  });

  constructor() {
    this.cubeWrapEl = document.getElementById('cubeWrap');
    this.cubeBoxEl = this.cubeWrapEl.querySelector('#cubeBox');
    this.cubePosterEl = this.cubeBoxEl.querySelector('#cubeImage');

    const cubeTemplates = this.cubeWrapEl.querySelector('#cubeTemplates');
    this.cubeBoxEl.classList.add('cube-box');
    this.cubeBoxEl.appendChild(cubeTemplates.querySelector('#tmplCubeBoxDesktop').content);
    this.cubeWrapEl.removeChild(cubeTemplates);

    this.cubeFrameEl = this.cubeBoxEl.querySelector('#cubeFrame');

    cubeService.setCube(this.cubeBoxEl);

    if (!this.cubePosterEl.complete) {
      this.cubePosterEl.addEventListener('load', this.onPosterLoad, { once: true });
      this.cubePosterEl.addEventListener('error', this.onPosterLoad, { once: true });
    } else {
      this.onPosterLoad();
    }

    this.handleCubeIsReady();

    loaderService.isLoaded$
      .pipe(filter(isLoaded => isLoaded), take(1))
      .subscribe(() => {
        this.cubeWrapEl?.classList.add('bounce');
        this.cubeFrameEl?.contentWindow?.postMessage(this.active ? { start: 1 } : { stop: 1 }, '*');
      });

    window.addEventListener('message', this.onMessage.bind(this));
  }

  onMessage({ data, source: cubeWindow }) {
    if (!(data instanceof Object) || data.src !== 'CUB') return;
    // console.log('data-cube=>>> ', data);

    if (data.progress && !this.loaded) {
      const loadingProgress = Math.min(100, data.progress);
      if (loadingProgress > this.loadingProgress) {
        this.loadingProgress = loadingProgress;
        if (this.loadingProgress === 100) {
          this.loaded = true;
          loaderService.setIsCubeReady();
        }
      }
    } else if (data.getAnimationStatus && cubeWindow) {
      cubeWindow.postMessage(this.active ? { start: 1 } : { stop: 1 }, '*');
    } else if (data.started && this.firstStart) {
      this.firstStart = false;
      setTimeout(() => {
        this.cubePosterEl.classList.add('hide');
      }, 100);
    }
  }

  // @AutoUnsubscribe()
  handleCubeIsReady() {
    return cubeService.cubeActive$.subscribe((isActive) => {
      this.active = isActive;
      this.cubeFrameEl?.contentWindow?.postMessage(this.active ? { start: 1 } : { stop: 1 }, '*'); // TODO: ??
    });
  }
}
//endregion
//region -------------------------CUBE MOBILE-------------------------
class CubeMobile {
  cubeBoxEl;
  cubeWrapEl;
  cubeVideoEl;
  cubeVideo;

  onPosterLoad = (() => {
    loaderService.setIsCubeReady();

    this.cubePosterEl.removeEventListener('load', this.onPosterLoad);
    this.cubePosterEl.removeEventListener('error', this.onPosterLoad);
  });

  constructor() {
    this.cubeWrapEl = document.getElementById('cubeWrap');
    this.cubeBoxEl = this.cubeWrapEl.querySelector('#cubeBox');
    this.cubePosterEl = this.cubeBoxEl.querySelector('#cubeImage');

    const cubeTemplates = this.cubeWrapEl.querySelector('#cubeTemplates');
    this.cubeBoxEl.classList.add('cube-box-mobile');
    this.cubeBoxEl.appendChild(cubeTemplates.querySelector('#tmplCubeBoxMobile').content);
    this.cubeWrapEl.removeChild(cubeTemplates);


    this.cubeVideoEl = this.cubeBoxEl.querySelector('#cubeVideo');
    this.cubeVideo = new CubeVideo(this.cubeVideoEl);

    cubeService.setCube(this.cubeBoxEl);

    if (!this.cubePosterEl.complete) {
      this.cubePosterEl.addEventListener('load', this.onPosterLoad, { once: true });
      this.cubePosterEl.addEventListener('error', this.onPosterLoad, { once: true });
    } else {
      this.onPosterLoad();
    }

    this.cubeVideoEl.addEventListener('loadeddata', () => {
      this.onPosterLoad();
      this.cubePosterEl.classList.add('hide');
    }, { once: true });

    this.handleCubeIsReady();
  }

  handleCubeIsReady() {
    const visibilityChange$ = merge(
      of(true), // initial value
      fromEvent(document, 'visibilitychange')
        .pipe(map(() => (document.visibilityState === 'visible'))));

    combineLatest([visibilityChange$, cubeService.cubeActive$])
      .subscribe(([isVisible, isActive]) => {
        if (isVisible && isActive) {
          this.cubeWrapEl.classList.add('bounce');
          this.disableCube(false);
        } else {
          this.disableCube();
        }
      });
  }

  disableCube(status = true) {
    if (status) { // disable
      this.cubeVideo.pause();
    } else { // enable
      this.cubeVideo.play();
    }
  }
}
//endregion
//region -------------------------EARTH-------------------------------

class Earth {
  loaded = false;
  loadingProgress = 0;
  activeClient = null;
  markerPosition = null;
  earthContainerEl;
  earthFrameEl;
  earthWindow;
  preloaderEarthEl;
  progressEarthEl;
  constructor() {
    this.earthContainerEl = document.getElementById('earth_container');
    this.earthFrameEl = document.getElementById('earth_frame');
    this.preloaderEarthEl = document.getElementById('earth-preloader');
    this.progressEarthEl = document.getElementById('earth-progress');

    this.markerLabelEl = document.getElementById('marker_label');
    this.markerLabelDtEl = this.markerLabelEl.querySelector('dt');
    this.markerLabelDdEl = this.markerLabelEl.querySelector('dd');

    this.initEarthAnimate();

    window.addEventListener('message', this.onMessage.bind(this));
  }

  onMessage({ data, source }) {
    if (!(data instanceof Object) || data.src !== 'EAR') return;
    // console.log('data-earth=>>> ', data);

    this.earthWindow = source;
    if(data.ready) {
      // this.earthWindow = source;
    } else if (data.progress && !this.loaded) {
      const loadingProgress = Math.min(100, data.progress);
      if (loadingProgress > this.loadingProgress) {
        this.loadingProgress = loadingProgress;

        this.progressEarthEl.textContent = `${loadingProgress}%`;

        if (this.loadingProgress === 100) {
          this.loaded = true;
          this.earthContainerEl.removeChild(this.preloaderEarthEl);
        }
      }
    } else if (data.getAnimationStatus && this.earthAnimated) {
      this.earthWindow?.postMessage({ animate: 1 }, '*');
    } else if (data.getClients) {
      this.earthWindow?.postMessage({ setClients: markerClients }, '*');
    } else if (data.setClientData) {
      const client = data.setClientData;
      if (client.uuid) {
        this.activeClient = client;
      } else {
        this.activeClient = null;
        this.markerPosition = null;
      }
      this.updateMarkerLabel({ updateData: true });
    } else if (data.setClientPosition) {
      this.markerPosition = data.setClientPosition;
      this.updateMarkerLabel({ updatePosition: true });
    } else if (data.setSwipeData) {
      const swipeData = data.setSwipeData;
      if (!swipeData.left
        && !swipeData.right
        && (swipeData.top || swipeData.bottom)
        && (swipeData.polarStartAngle === swipeData.polarEndAngle
          || swipeData.speedY > 1)) {
        window.scrollBy({
          top: swipeData.difY,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  initEarthAnimate() {
    return scrollTrigger.registerElement(this.earthFrameEl, {
      start: 'top center',
      end: 'bottom center',
      once: false
    })
      .subscribe((data) => {
      if (data.event === TriggerEvents.ENTER && !this.earthAnimated) {
        this.earthWindow && this.earthWindow.postMessage({ animate: 1 }, '*');
        this.earthAnimated = true;
      } else if ((data.event === TriggerEvents.ENTER || data.event === TriggerEvents.TOGGLE) && this.earthAnimated) {
        this.earthWindow && this.earthWindow.postMessage({ orbit: 1 }, '*'); // just to start for 10 sec
      }
    });
  }

  updateMarkerLabel(options = {}) {
    const { updateData, updatePosition } = options;
    this.markerLabelEl.style.display = (this.activeClient && this.markerPosition) ? 'block' : 'none';

    if (updatePosition && this.markerPosition) {
      this.markerLabelEl.style.left = `${this.markerPosition.x}px`;
      this.markerLabelEl.style.top = `${this.markerPosition.y}px`;
      this.markerLabelEl.style.opacity = this.markerPosition.o;
    }

    if (updateData && this.activeClient) {
      this.markerLabelDtEl.textContent = this.activeClient.organization || '';
      this.markerLabelDdEl.textContent = `${this.activeClient.city}, ${this.activeClient.country}`;
    }
  }
}
//endregion
//region -------------------------REVIEWS-----------------------------
const setupReviews = (function () { // Function Scope
  let reviewsSection, reviewsSliderEl, reviewsLeftBtn, reviewsRightBtn, reviewsProgressBar, slides;
  let reviewsTimer, scrollToTimeout;
  let currentSlideEl;
  let slidesRendered = [];
  let slidesOriginal = [];
  let scrollTriggerActive = true;
  let firstTransitionExecuted = false;
  let dragging = false;
  let reqAnimFrID = null;

  function setupReviews() {
    reviewsSection = document.getElementById('reviews');
    reviewsSliderEl = reviewsSection.querySelector('#slider');
    reviewsLeftBtn = reviewsSection.querySelectorAll('#leftBtn');
    reviewsRightBtn = reviewsSection.querySelectorAll('#rightBtn');
    reviewsProgressBar = reviewsSection.querySelectorAll('#progress');
    slides = reviewsSliderEl.querySelectorAll('.slide');

    reviewsSliderEl.addEventListener('scroll', gotoCurrentSlide);
    reviewsLeftBtn.forEach(btn => btn.addEventListener('click', gotoPrevSlide));
    reviewsRightBtn.forEach(btn => btn.addEventListener('click', gotoNextSlide));

    slides.forEach((slide) => {
      if (!!slide.dataset['original']) {
        slidesOriginal.push(slide);
      }
      slidesRendered.push(slide);
    });

    currentSlideEl = slidesRendered[slidesOriginal.length - 1];

    setTimeout(() => {
      gotoSlide(currentSlideEl, 0);
      initScrollTrigger();
      initDraggable();
      initFirstTransition();
    }, 0);
  }

  function gotoCurrentSlide() {
    if (!scrollTriggerActive || dragging) {
      return;
    }

    clearTimeout(scrollToTimeout);

    scrollToTimeout = setTimeout(gotoSlide.bind(null, currentSlideEl), 200);
  }

  function gotoSlide(slideEl, duration = 0.5) {
    cancelAnimationFrame(reqAnimFrID);
    updateProgressBar();
    scrollTriggerActive = false;

    if (reviewsTimer) {
      clearTimeout(reviewsTimer);
    }

    const scrollOpts = {
      top: 0,
      left: slideEl.offsetLeft,
      behavior: 'smooth'
    };

    const onComplete = () => {
      if (!!slideEl.dataset['original']) {
        scrollTriggerActive = true;
      } else {
        currentSlideEl = slidesOriginal[+(slideEl.dataset['slideIndex'] || 0)];
        gotoSlide(currentSlideEl, 0);
      }
    };

    const checkIfComplete = () => {
      reqAnimFrID = requestAnimationFrame(() => {
        if(scrollOpts.left === reviewsSliderEl.scrollLeft) {
          onComplete();
        } else {
          checkIfComplete();
        }
      });
    };

    if (duration) {
      reviewsSliderEl.scrollTo(scrollOpts);
      checkIfComplete();
    } else {
      reviewsSliderEl.scrollLeft = scrollOpts.left;
      onComplete();
    }
  }

  function gotoPrevSlide() {
    currentSlideEl = slidesRendered[+currentSlideEl.dataset['slideDomIndex'] - 1]
      || slidesRendered[0];
    gotoCurrentSlide();
  }

  function gotoNextSlide() {
    currentSlideEl = slidesRendered[+currentSlideEl.dataset['slideDomIndex'] + 1]
      || slidesRendered[slidesRendered.length - 1];
    gotoCurrentSlide();
  }

  function initScrollTrigger() {
    const threshold = 0.50;
    const intersectionObserver = new IntersectionObserver(([slideEntry]) => {
      if (!firstTransitionExecuted || !scrollTriggerActive) {
        return;
      }

      if (slideEntry.intersectionRatio >= threshold) {
        currentSlideEl = slideEntry.target;
      }

      gotoCurrentSlide();
      updateProgressBar();
    }, {
      root: reviewsSliderEl,
      threshold
    });

    slidesRendered.forEach((slideEl) => {
      intersectionObserver.observe(slideEl);
    });
  }

  function updateProgressBar() {
    const currentPosition = +(currentSlideEl?.dataset['slideIndex'] || 0);
    const progressWidth = 100 * (currentPosition + 1) / slidesOriginal.length;
    reviewsProgressBar.forEach(bar => (bar.style.width = `${progressWidth}%`));
  }

  function firstTransition() {
    currentSlideEl = slidesRendered[+currentSlideEl.dataset['slideDomIndex'] + 1];
    gotoSlide(currentSlideEl, 1);
    firstTransitionExecuted = true;
  }

  function initDraggable() {
    if (isTouchDevice()) {
      return;
    }

    let startScroll = reviewsSliderEl.scrollLeft;
    let startX = 0;

    const onMouseMove = (e) => {
      reviewsSliderEl.scrollLeft = startScroll - (e.pageX - startX);

      reviewsSliderEl.style.userSelect = 'none';
    }

    const onMouseUp = (e) => {
      document.removeEventListener('mousemove', onMouseMove);

      setTimeout(() => {
        dragging = false;
        gotoCurrentSlide();
      }, 0);

      reviewsSliderEl.style.cursor = 'grab';
      reviewsSliderEl.style.userSelect = '';
    };

    const onMouseDown = (e) => {
      startScroll = reviewsSliderEl.scrollLeft;
      startX = e.pageX;
      dragging = true;

      document.addEventListener('mousemove', onMouseMove, false);
      document.addEventListener('mouseup', onMouseUp, { once: true });

      reviewsSliderEl.style.cursor = 'grabbing';
    };

    reviewsSliderEl.addEventListener('mousedown', onMouseDown, false);
    reviewsSliderEl.style.cursor = 'grab';
  }

  function initFirstTransition() {
    return scrollTrigger.registerElement(reviewsSliderEl, {
      start: 'top center',
      once: true
    })
      .subscribe((data) => {
        if (data.event === TriggerEvents.ENTER) {
          firstTransition();
        }
      });
  }

  return setupReviews;
})();
//endregion
//region -------------------------PORTFOLIO---------------------------
const setupPortfolio = (function () { // Function Scope
  let portfolioList;
  let portfolioItems;
  const portfolioItemsMethods = [];
  let portfolioTimeout, timeUpdateRAFId;
  let portfolioIntersectionObserver;

  let isTileLayout = false;

  function setupPortfolio() {
    portfolioList = document.getElementById('portfolioList');
    portfolioItems = [...portfolioList.querySelectorAll('.portfolio-item')];

    let intersecObsTmId = null;

    portfolioIntersectionObserver = new IntersectionObserver((entries) => {
      if (isTileLayout) {
        return;
      }

      entries.forEach((entry) => {
        const { isIntersecting, target: figure } = entry;
        const parentEl = figure.closest('#portfolioItem');
        const { index } = parentEl.dataset;
        if (isIntersecting) {
          clearTimeout(intersecObsTmId);
          intersecObsTmId = setTimeout(() => {
            portfolioItemsMethods[index] && portfolioItemsMethods[index].play();
          }, 200);
        } else {
          portfolioItemsMethods[index] && portfolioItemsMethods[index].stop();
        }
      });
    }, {
      root: null,
      threshold: 0.9
    });

    addEventListener('resize', resize);

    setTimeout(() => {
      portfolioItems.forEach((el) => {
        setClass(el);
        registerElementListeners(el);
        addPlayPauseListeners(el);
      });
      resize();
    }, 0);
  }

  function resize() {
    isTileLayout = false;
    let itemX = portfolioItems[0].offsetLeft;
    portfolioItems.forEach((itemEl) => {
      const cover = itemEl.querySelector('#cover');

      if (cover) {
        const videoEl = itemEl.querySelector('#video');
        const canvas = itemEl.querySelector('#canvas');

        let width = cover.offsetWidth;
        let height = width / itemEl.dataset.aspect;

        canvas.setAttribute('width', `${width}`);
        canvas.setAttribute('height', `${height}`);

        videoEl.style.width = `${width}px`;
        videoEl.style.height = `${height}px`;

        isTileLayout = isTileLayout || (itemX !== itemEl.offsetLeft);
      }
    });

    if (isTileLayout) {
      portfolioList.classList.remove('portfolio-vertical-layout');
    } else {
      portfolioList.classList.add('portfolio-vertical-layout');
    }
  }

  function registerElementListeners(el) {
    const videoEl = el.querySelector('#video');
    const figure = el.querySelector('#figure');

    videoEl.addEventListener('loadeddata', () => {
      figure.classList.add('loaded');
    });

    scrollTrigger.registerElement(el, {
      start: 'top 20%',
      once: true
    })
      .subscribe((data) => {
        if (data.event === TriggerEvents.ENTER) {
          el.animated = true;
          el.classList.remove('initial');
        }
      });
  }

  function addPlayPauseListeners(el) {
    const figure = el.querySelector('#figure');
    const videoEl = el.querySelector('#video');
    const ctx = el.querySelector('#canvas').getContext('2d');
    const index = el.dataset.index;

    portfolioItemsMethods[index] = {
      play: playVideo.bind(null, el, figure, videoEl, ctx),
      stop: stopVideo.bind(null, figure, videoEl)
    };
    const playThisVideo = () => {
      if (!isTileLayout) {
        return;
      }

      portfolioItemsMethods[index].play();
    };

    const stopThisVideo = () => {
      if (!isTileLayout) {
        return;
      }

      portfolioItemsMethods[index].stop();
    };


    figure.addEventListener('touchstart', playThisVideo, { passive: true });

    figure.addEventListener('mouseover', playThisVideo, { passive: true });

    figure.addEventListener('touchend', stopThisVideo, { passive: true });

    figure.addEventListener('mouseout', stopThisVideo, { passive: true });

    portfolioIntersectionObserver.observe(figure);

    setTimeout(() => {
      videoEl.setAttribute('preload', 'metadata');
    }, Math.round(Math.random()*1000)); // randomise videos loading
  }

  function playVideo(el, figure, videoEl, ctx) {
    if (!el.animated) {
      return;
    }

    clearTimeout(portfolioTimeout);
    cancelAnimationFrame(timeUpdateRAFId);
    portfolioTimeout = setTimeout(handlePlayVideo.bind(null, figure, videoEl, ctx), 250);
  }

  function stopVideo(figure, videoEl) {
    clearTimeout(portfolioTimeout);
    cancelAnimationFrame(timeUpdateRAFId);

    figure.classList.remove('playing');
    videoEl.pause();
    videoEl.currentTime = 0;
  }

  function handlePlayVideo(figure, videoEl, ctx) {
    figure.classList.add('playing');
    videoEl.play();
    if (isTileLayout) {
      requestTimeUpdate(ctx, videoEl);
    }
  }

  function requestTimeUpdate(ctx, videoEl) {
    if (videoEl.paused || videoEl.ended) {
      return;
    }

    drawShadowFrame(ctx, videoEl);
    timeUpdateRAFId = requestAnimationFrame(requestTimeUpdate.bind(null, ctx, videoEl));
  }

  function drawShadowFrame(ctx, videoEl) {
    const width = videoEl.offsetWidth;
    const height = videoEl.offsetHeight;

    ctx.drawImage(videoEl, 0, 0, width, height);
  }

  function setClass(el) {
    const index = el.getAttribute('data-index');
    el.classList.add(`portfolio-item-${index}`);
  }

  return setupPortfolio;
})();
//endregion
//region -------------------------WORKING WITH US---------------------

let chatMessagesAnimateEl, stickyEl;
let stickyElHeight;

function animateWorkingWithUsElements() {
  const animationPlaces = document.querySelectorAll('.animation-place');

  setTimeout(() => {
    [...animationPlaces].forEach((animationPlaceEl) => {
      animateImage(animationPlaceEl);
      animateVideo(animationPlaceEl);
      animateChat(animationPlaceEl);
    });
  }, 0);
}

function animateImage(animationPlaceEl) {
  const img = animationPlaceEl.querySelector('img');

  if (img) {
    registerAnimationPlaceElScroll(animationPlaceEl)
      .subscribe((data) => {
        if (data.event === TriggerEvents.ENTER) {
          img.classList.remove('inactive');
        }
      });
  }
}

function animateVideo(animationPlaceEl) {
  let video = animationPlaceEl.querySelector('video');

  if (video) {
    video.muted = true;

    video.addEventListener('mouseover', mouseOver);
    video.addEventListener('mouseout', mouseOut);

    registerAnimationPlaceElScroll(animationPlaceEl)
      .subscribe((data) => {
        if (data.event === TriggerEvents.ENTER) {
          video.play();
        }
      });
  }
}

function mouseOver(event) {
  event.target.loop = true;
  event.target.play();
}

function mouseOut(event) {
  event.target.loop = false;
}

function animateChat(animationPlaceEl) {
  const chatMessagesEl = animationPlaceEl.querySelector('.chat-messages-wrap');

  if (chatMessagesEl) {
    chatMessagesAnimateEl = chatMessagesEl;
    stickyEl = animationPlaceEl.querySelector('.chat-messages');
    const fakeEl = animationPlaceEl.querySelector('.fake');

    initStickyElement();
    registerChatMsgsScroll();
    registerFakeElement(fakeEl);
  }
}

function registerAnimationPlaceElScroll(animationPlaceEl) {
  return scrollTrigger.registerElement(animationPlaceEl, {
    start: 'top center',
    once: true
  });
}

function initStickyElement() {
  ({ height: stickyElHeight } = stickyEl.getBoundingClientRect());
  chatMessagesAnimateEl.style.height = `${stickyElHeight * 2}px`;
  chatMessagesAnimateEl.style.position = 'relative';
}

function registerFakeElement(el) {
  scrollTrigger.registerElement(el, {
    start: 'top top'
  })
    .subscribe((data) => {
      if (data.event === TriggerEvents.ENTER) {
        handleStickyElementOnTriggerFinish(data.direction);
      }
      if (data.event === TriggerEvents.TOGGLE) {
        handleStickyElementOnTriggerStart();
      }
    });
}

function registerChatMsgsScroll() {
  let currentProgress = 0;
  let step = 0;

  const handleProgress = () => {
    chatMessagesAnimateEl.classList.remove(`progress-${currentProgress}`);
    currentProgress = step;
    chatMessagesAnimateEl.classList.add(`progress-${currentProgress}`);
  }

  scrollTrigger.registerElement(chatMessagesAnimateEl, {
    start: 'top top',
    end: 'center top',
    progress: true
  })
    .subscribe((data) => {
      if (data.event === TriggerEvents.ENTER) {
        handleStickyElementOnTriggerStart();
      }

      if (data.event === TriggerEvents.TOGGLE) {
        handleStickyElementOnTriggerFinish(data.direction);
      }

      if (data.event === TriggerEvents.PROGRESS) {
        step = roundToStep(data.progress, 0.25);
        if (currentProgress !== step) {
          requestAnimationFrame(handleProgress);
        }
      }
    });
}

function handleStickyElementOnTriggerStart() {
  stickyEl.style.position = 'fixed';
  stickyEl.style.top = '0';
  stickyEl.style.transform = '';
}

function handleStickyElementOnTriggerFinish(direction) {
  stickyEl.style.position = ''

  if (direction === 1) {
    return;
  }

  stickyEl.style.transform = `translate(0px, ${stickyElHeight}px)`;
}

function roundToStep(number, step) {
  return Math.round(number / step ) * step * 100;
}
//endregion
//region -------------------------COMMON------------------------------

function registerLiveLine(el) {
  scrollTrigger.registerElement(el, {
    start: 'top 20%',
    once: true
  })
    .subscribe(() => {
      el.classList.add('active');
    });
}

function setLiveLine() {
  const liveLines = document.querySelectorAll('.live-line');
  liveLines.forEach((liveLine) => {
    registerLiveLine(liveLine);
  });
}

let splashBtns = [];

function initSplashBtns() {
  splashBtns = document.querySelectorAll('.splash-btn');

  registerSplashBtns(splashBtns);
}

function handleDomContentLoad() {
  if (window.IS_MOBILE) {
    new CubeMobile();
  } else {
    new CubeDesktop();
  }

  new Earth();

  setTriggerOnGreySections();
  initLogosScroll();
  handleContactUs();
  goToContactUs();
  setupReviews();
  setupPortfolio();
  setLiveLine();
  animateWorkingWithUsElements();
  initSplashBtns();
  connectTextSplit();
}

if (document.readyState === 'loading') {
  // Loading hasn't finished yet
  addEventListener('DOMContentLoaded', () => {
    handleDomContentLoad();
  }, { once: true });
} else {
  // `DOMContentLoaded` has already fired
  handleDomContentLoad();
}

// metaService.setWebsiteMeta(); // TODO: move to template (actual for SSR only)
//endregion
