import { fontfacesets } from 'fontfacesets';
import { loaderService } from './loader-service';

let activated = false;

function _loadFonts () {
  if (!('FontFace' in window)) {
    return;
  }

  const len = fontfacesets.length;
  let counter = 0;

  return Promise.all(fontfacesets.map((font) => {
    const fontFace = new FontFace(font.family, `local("${font.name}"), local("${font.postscript}"), url("${font.url}") format("${font.format}")`, {
      style: font.style,
      weight: font.weight,
      display: 'swap',
    });

    return fontFace.load().then(() => {
      document.fonts.add(fontFace);
    }).catch(() => {}).then(() => {
      counter++;
      loaderService.setFontsProgress(Math.floor(counter/len*100));
    });
  }));
}

export function loadFonts() {
  if (activated) return;

  activated = true;

  _loadFonts().then(() => {
    loaderService.setIsFontsLoaded();
  });
}
