import { BehaviorSubject, fromEvent, map, take } from 'rxjs';

class LoaderService {
  progressBarEl;

  progressEl;

  #isLoaded$ = new BehaviorSubject(false);

  #progress$ = new BehaviorSubject(0);

  #fontsProgress$ = new BehaviorSubject(0);

  #isCubeReady = false;
  #isFontsLoaded = false;

  #interval;

  constructor() {
    // this.handlePageLoaded = this.handlePageLoaded.bind(this);
    this.handleLoadingPercents = this.handleLoadingPercents.bind(this);

    if (document.readyState === 'loading') {
      // Loading hasn't finished yet
      document.addEventListener('DOMContentLoaded', this.handleLoadingPercents, { once: true });
    } else {
      // `DOMContentLoaded` has already fired
      this.handleLoadingPercents();
    }

    // just in case :)
    fromEvent(window, 'load').pipe(take(1)).subscribe(() => {
      if (!this.#isLoaded$.getValue()) {
        this.#isLoaded$.next(true);
      }
    });
  }

  handleLoadingPercents() {
    if (!this.progressBarEl || !this.progressEl) {
      this.progressBarEl = document.getElementById('progress_bar');
      this.progressEl = document.getElementById('progress');
    }

    if (!window.IS_LANDING) {
      this.#isCubeReady = true;
    }

    this.#progress$.subscribe((value) => {
      let progress = `${value}%`;
      this.progressEl.textContent = progress;
      this.progressBarEl.style.transform = `translate(${progress}, 0)`;
    });
  }

  setFontsProgress(value) {
    if (value <= this.#fontsProgress$.getValue()) return;

    this.#fontsProgress$.next(Math.min(100, value));

    if (this.#fontsProgress$.getValue() === 100) {
      this.#fontsProgress$.complete();
    }
    this.updateLoadingStatus();
  }

  setIsFontsLoaded() {
    this.setFontsProgress(100);
    this.#isFontsLoaded = true;
    this.updateLoadingStatus();
  }

  setIsCubeReady() {
    if (this.#isCubeReady) return;

    this.#isCubeReady = true;
    this.updateLoadingStatus();
  }

  get isLoaded$() {
    return this.#isLoaded$.asObservable();
  }

  updateLoadingStatus() {
    let progress = this.#fontsProgress$.getValue();

    if (!this.#isCubeReady) {
      progress = Math.floor(progress * 0.9);
    }

    if (progress !== this.#progress$.getValue()) {
      this.#progress$.next(progress);
    }

    if (progress === 100) {
      setTimeout(() => {
        if (!this.#isLoaded$.getValue()) {
          this.#isLoaded$.next(true);
        }
      }, 500);
    }
  }
}

export const loaderService = new LoaderService();
