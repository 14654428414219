export const markerClients = [
  {
    uuid: "0b1280ef-5aae-4822-847d-dc54e509d88c",
    name: "Kaveh Azari",
    organization: "99barrels",
    country: "United States",
    city: "San Francisco",
    url: "https://www.99barrels.co/",
    description: "99 Barrels aims to simplify the craft alcohol industry by creating one marketplace where craft alcohol producers can manage, market and sell directly to - both businesses and consumers globally.",
    point: {
      x: -0.3480992432360534,
      y: 0.3267791894532577,
      z: -0.22086122920231627
    }
  },
  {
    uuid: "2ee1f917-0e85-497f-9a39-885e1641050b",
    name: "Vlad Vlaskin",
    organization: "AdMoment",
    country: "Germany",
    city: "Berlin",
    url: "https://www.admoment.co/",
    description: "AdMoment is Moscow-based advertising technology company with global roots. I led the building of Demand Side Platform for Real Time Bidding focused on traffic for mobile devices.",
    point: {
      x: 0.0661414382247352,
      y: 0.40800246538420043,
      z: 0.2950218314710882
    }
  },
  {
    uuid: "e02ff3e9-511b-4d1f-8733-8fb345e5fb57",
    name: "Vlad Vlaskin",
    organization: "Locarta",
    country: "Germany",
    city: "Berlin",
    url: "",
    description: "Locarta - Actionable mobile insights for the physical world. A startup to deliver location intelligence for retailers and Ad agencies.",
    point: {
      x: 0.0718013597729625,
      y: 0.40506205208003626,
      z: 0.3036135951924718
    }
  },
  {
    uuid: "5048a214-d526-4a8a-8700-3a04b0c643e8",
    name: "Able Joseph",
    organization: "Aisle",
    country: "India",
    city: "Bangalore",
    url: "https://www.aisle.co/",
    description: "Aisle is a dating app where single persons meet each other to establish meaningful relationships.",
    point: {
      x: 0.4891231693899307,
      y: 0.11650818921253901,
      z: 0.1099971137103782
    }
  },
  {
    uuid: "d5c8cacc-c87b-425d-8794-2b74ae90bc0a",
    name: "Sean Maney",
    organization: "Bookedby / SalonUltimate",
    country: "United States",
    city: "Boston",
    url: "https://salonultimate.com/",
    description: "SalonUltimate is an innovative provider of flexible business management software built for the beauty industry, by the beauty industry.",
    point: {
      x: -0.3546862931886821,
      y: 0.3490931186193136,
      z: 0.1175618346006838
    }
  },
  {
    uuid: "6ffc0d29-ed3c-46cf-8d51-20936c13a550",
    name: "Adrian Fluri",
    organization: "audiolingo",
    country: "Switzerland",
    city: "Luzern",
    url: "http://www.audiolingo.com/",
    description: "AdMoment is Moscow-based advertising technology company with global roots. I led the building of Demand Side Platform for Real Time Bidding focused on traffic for mobile devices.",
    point: {
      x: 0.048513755109153074,
      y: 0.38409254124284925,
      z: 0.3368246774519872
    }
  },
  {
    uuid: "97fc8cc7-fa79-4d63-ba56-47b9cb36095e",
    name: "Tony Shannon",
    organization: "Leeds City Council",
    country: "United Kingdom",
    city: "Leeds",
    url: "https://www.leeds.gov.uk/",
    description: "Backend & Testing",
    point: {
      x: -0.007210748099021721,
      y: 0.41369364210040765,
      z: 0.29688980044882396
    }
  },
  {
    uuid: "ea2d3134-a378-4552-9fa4-67e2d44ce228",
    name: "Simranjit Butalia",
    organization: "BeBop Technology",
    country: "United States",
    city: "Encino",
    url: "https://www.beboptechnology.com/",
    description: "",
    point: {
      x: -0.385804826035791,
      y: 0.29395982949811655,
      z: -0.20936322616406003
    }
  },
  {
    uuid: "3646f05f-c35a-40f6-9d0b-69bce61e6886",
    name: "Anup Mehta",
    organization: "bkynd",
    country: "United Kingdom",
    city: "London",
    url: "https://www.bkynd.org/",
    description: "bkynd - Tech startup facilitating acts of kindness",
    point: {
      x: 0.0009885191884181307,
      y: 0.40714200908559905,
      z: 0.3165395997878287
    }
  },
  {
    uuid: "05e95207-58e1-4c03-983c-6c7696f120a5",
    name: "David Brakha",
    organization: "BlackBell",
    country: "United States",
    city: "New York",
    url: "https://www.blackbell.com/en",
    description: "BlackBell is a Saas-solution for businesses. It helps to promote services, handle the orders, manage events and your calendar, accept payments etc.",
    point: {
      x: -0.359260000933446,
      y: 0.33033299769376295,
      z: 0.12870023160511607
    }
  },
  {
    uuid: "b148b961-47a6-416e-a28a-a787310e8198",
    name: "Naina Tiwari",
    organization: "Design Everest",
    country: "United States",
    city: "Mountain View",
    url: "https://designeverest.com/",
    description: "Design Everest mobile app allows DE professionals provide their services to all their customers and coordinate projects end to end",
    point: {
      x: -0.3556837287677479,
      y: 0.3199720267675556,
      z: -0.21576889344675992
    }
  },
  {
    uuid: "fd701de6-ac1e-467d-a801-ecbad10d8cb8",
    name: "Adlina Yeow",
    organization: "DogSpa",
    country: "Canada",
    city: "Calgary",
    url: "",
    description: "",
    point: {
      x: -0.29766308252145,
      y: 0.4044684364778568,
      z: -0.12848882003161824
    }
  },
  {
    uuid: "1ce7311d-7792-442c-9f51-56c9f356cd06",
    name: "Adlina Yeow",
    organization: "Sharedkey",
    country: "Canada",
    city: "Calgary",
    url: "",
    description: "",
    point: {
      x: -0.30869480307634706,
      y: 0.403211628944411,
      z: -0.11154860983989666
    }
  },
  {
    uuid: "25923c35-96ba-4ce8-9a4b-2ca3a79835d2",
    name: "Andrew Livingston",
    organization: "Doxy.me",
    country: "United States",
    city: "New York",
    url: "https://doxy.me/",
    description: "",
    point: {
      x: -0.41208187173467314,
      y: 0.2982107046051022,
      z: 0.09800641454808781
    }
  },
  // TODO: Longmont, Colorado
  {
    uuid: "d2ef533d-aee4-4873-b37a-28492d5b013c",
    name: "Anish Parikh",
    organization: "Fairwords",
    country: "United States",
    city: "Denver",
    url: "",
    description: "Fairwords is the first patented AI-powered communications protection software that proactively trains employees as they type",
    point: {
      x: -0.3629386523464672,
      y: 0.35213489108697077,
      z: -0.13503303692249
    }
  },
  {
    uuid: "d11cebac-3c41-419b-a523-8b7d595a1d67",
    name: "Brittany Sturla",
    organization: "My Family Lounge",
    country: "United States",
    city: "Foster City",
    url: "",
    description: "Fit App",
    point: {
      x: -0.35171066068897855,
      y: 0.3097463704304305,
      z: -0.20537347947017293
    }
  },
  {
    uuid: "b5c602b2-d2ed-47e9-9c92-7e64bca208e7",
    name: "Alexander Rauser",
    organization: "Prototype",
    country: "United Arab Emirates",
    city: "Dubai",
    url: "https://prototype.net/alexander-rauser",
    description: "",
    point: {
      x: 0.37343861584580784,
      y: 0.2092533243570158,
      z: 0.27241201241281465
    }
  },
  {
    uuid: "b3dff70b-d0d6-454b-ae4d-ddab1943efc7",
    name: "Birajini Devi",
    organization: "Infiniticube Services Pvt Ltd",
    country: "India",
    city: "Noida",
    url: "https://infiniticube.com/",
    description: "",
    point: {
      x: 0.4511995811793795,
      y: 0.21111077165676526,
      z: 0.10674068063745265
    }
  },
  {
    uuid: "af231274-12b2-4cfa-be83-59736e2e664c",
    name: "Peter Tanapat",
    organization: "Goodshop",
    country: "United States",
    city: "New York",
    url: "https://www.goodshop.com/",
    description: "",
    point: {
      x: -0.37055295876964406,
      y: 0.3297621100019518,
      z: 0.10645351135086305
    }
  },
  {
    uuid: "74b372e5-78cd-400e-b17c-f6fa4617e72b",
    name: "Sam G",
    organization: "Datasapiens",
    country: "Czech Republic",
    city: "Prague",
    url: "https://www.datasapiens.co.uk/",
    description: "",
    point: {
      x: 0.07750304389449365,
      y: 0.3962545404387742,
      z: 0.31306451635546634
    }
  },
  {
    uuid: "8b57e163-7834-44ed-a717-43309e674079",
    name: "Stefano Vaghi",
    organization: "Elever Srl",
    country: "Italy",
    city: "Milano",
    url: "https://www.elever.it/",
    description: "",
    point: {
      x: 0.06471833202569699,
      y: 0.3745742667163427,
      z: 0.3577262486484355
    }
  },
  {
    uuid: "6bf38e58-b0aa-48c5-90c4-7665e7bc304d",
    name: "Mikko Maki-Rahkola",
    organization: "Dojanga",
    country: "Finland",
    city: "Nummela",
    url: "",
    description: "Ionic app",
    point: {
      x: 0.09514543836662553,
      y: 0.4436612552544939,
      z: 0.22760669972399072
    }
  },
  {
    uuid: "9fce7019-f20e-4bca-a281-689c227ee5f3",
    name: "Micah Coen",
    organization: "Creating Matter",
    country: "United States",
    city: "Newark",
    url: "https://www.creatingmatter.com/",
    description: "",
    point: {
      x: -0.3744200220128424,
      y: 0.3359729352117368,
      z: 0.08500815206475457
    }
  },
  {
    uuid: "d345d81e-4842-4e01-b4f1-c6db4de5982c",
    name: "Harsimran Singh",
    organization: "Lawcus",
    country: "United States",
    city: "Poway",
    url: "https://www.lawcus.com/",
    description: "Lawcus web app is a legal cases management service that gives a simplified way to organize contacts, tasks and matters.",
    point: {
      x: -0.3894555031418826,
      y: 0.2845954434519579,
      z: -0.19161548665528264
    }
  },
  {
    uuid: "929f066d-6e78-4b41-96f6-ba222c949369",
    name: "Siddharth Gorecha",
    organization: "Fourth Partner Energy",
    country: "India",
    city: "Pune",
    url: "",
    description: "MEAN APP",
    point: {
      x: 0.46344306158816817,
      y: 0.16850067906756008,
      z: 0.1408385233619135
    }
  },
  {
    uuid: "d747e1fa-1251-4e71-853d-6e4947ba58b0",
    name: "Oleg Kazantsev",
    organization: "MetroLab",
    country: "Russia",
    city: "Moscow",
    url: "https://www.metrolab.company/",
    description: "",
    point: {
      x: 0.18048631844475557,
      y: 0.4155355300634561,
      z: 0.2341661278995468
    }
  },
  {
    uuid: "43935510-8ea4-48e6-8651-3cc69f4e3afe",
    name: "Philipp Rappold",
    organization: "LINKS DER ISAR",
    country: "Germany",
    city: "Munich",
    url: "https://www.linksderisar.com/",
    description: "No Carb coach is an everyday app to input your personal data, keep diary, follow recommendations, search for healthy stuff in a huge database and share with friends.",
    point: {
      x: 0.06870124305280592,
      y: 0.39124957814298367,
      z: 0.32205192238199404
    }
  },
  {
    uuid: "113540ef-9f30-4a59-be9c-bbac162ee54e",
    name: "Allan Thorvaldsen",
    organization: "Panorama9",
    country: "Denmark",
    city: "Copenhagen",
    url: "",
    description: "Panorama9 - is a solution for IT devices monitoring and management",
    point: {
      x: 0.04285325021585907,
      y: 0.42592128588105177,
      z: 0.2714435948846766
    }
  },
  {
    uuid: "9db2b60f-2fdd-437a-81cd-bfdd1a912c65",
    name: "Charlie Beuthin",
    organization: "Clouded Room",
    country: "South Africa",
    city: "Johannesburg",
    url: "",
    description: "",
    point: {
      x: 0.227701233619979,
      y: -0.24840624874758077,
      z: 0.40111103725194874
    }
  },
  {
    uuid: "4c15b042-448e-45e7-824e-4bfe8ce4155e",
    name: "Alina Trigubenko",
    organization: "Profi.io",
    country: "United States",
    city: "Los Angeles",
    url: "https://www.profi.io/",
    description: "Profi.io - is an all-in-one solution for coaches, trainers, consultans, therapists",
    point: {
      x: -0.36816491122022693,
      y: 0.2964646277674805,
      z: -0.20843879762964443
    }
  },
  {
    uuid: "58616fb1-567b-4adb-a65f-abcbf7ff4def",
    name: "Simranjit Butalia",
    organization: "BM Consulting",
    country: "United States",
    city: "Los Angeles",
    url: "https://www.software.butaliamedia.com/",
    description: "Is an all-in-one platform for creators who daily create and share content",
    point: {
      x: -0.3697372798606679,
      y: 0.3081085654731007,
      z: -0.19089272762097317
    }
  },
  {
    uuid: "347e0fcc-c33b-4196-8bd8-339b0e5eccbf",
    name: "Ronald Evers",
    organization: "Wazzup",
    country: "Netherlands",
    city: "Arnhem",
    url: "",
    description: "",
    point: {
      x: 0.02997532886307082,
      y: 0.4048775834264629,
      z: 0.30701988625339066
    }
  },
  {
    uuid: "02e00b69-48f0-4a6e-8756-07f1fb438c60",
    name: "Masha Sharma",
    organization: "RealAtom",
    country: "United States",
    city: "Arlington",
    url: "https://www.realatom.com/",
    description: "",
    point: {
      x: -0.3932739173467179,
      y: 0.3163512062534872,
      z: 0.0869446311525884
    }
  },
  {
    uuid: "3a9be8fe-b8ea-45d1-ace0-e80e89dd7c95",
    name: "John Curtis",
    organization: "Curtis Digital",
    country: "United States",
    city: "Austin",
    url: "https://www.curtisdigital.com/",
    description: "ROI Calculator - A personal savings calculator that involves to input a set of user's initial data and makes a result in the way of savings forecast. ",
    point: {
      x: -0.4488743285062967,
      y: 0.23414514182074092,
      z: -0.07356220909470573
    }
  },
  {
    uuid: "e37a6ed7-f220-497e-b63c-c7d3b88de2e4",
    name: "Yaoquan Huang",
    organization: "Qbasis",
    country: "Singapore",
    city: "Singapore",
    url: "",
    description: "",
    point: {
      x: 0.4967736633214869,
      y: 0.01475787132101015,
      z: -0.11289350641681554
    }
  },
  {
    uuid: "995800ba-2b63-46c5-9ddc-f16ff926e4ff",
    name: "Trevor Chong",
    organization: "Trainerize",
    country: "Canada",
    city: "Vancouver",
    url: "https://www.trainerize.com/",
    description: "Trainerize is a training platform that empowers fitness professionals to stay better connected to their clients when training them online or individually.",
    point: {
      x: -0.29283934326313504,
      y: 0.3783246798523837,
      z: -0.19133542638209186
    }
  },
  {
    uuid: "2a10bc90-da58-44d9-9cfd-9d1d6956f661",
    name: "Jovanna Brooks",
    organization: "Valley Velocity",
    country: "United States",
    city: "Boston",
    url: "https://www.valleyvelocity.com/",
    description: "Vinify - Mobile app for order cases of drinks with delivery anywhere in the world",
    point: {
      x: -0.33988826214066575,
      y: 0.37236336528556835,
      z: 0.11027216188463423
    }
  },
  {
    uuid: "1c946373-acf7-443a-a3c7-78bfb13d69cf",
    name: "Peter Swaniker",
    organization: "Nimble Software Systems",
    country: "United States",
    city: "Cincinnati",
    url: "https://www.nimble.com/",
    description: "Ximble is an employee scheduling app that saves time, money and good mood. It helps to manage vacations, sick leaves, project assignments, labor costs.",
    point: {
      x: -0.39192961210045213,
      y: 0.330874142857699,
      z: 0.036615266101927
    }
  },
  {
    uuid: "2c892cd2-761e-4b2b-8938-b22bcefbca00",
    name: "Craig Cocker",
    organization: "Altron",
    country: "United Arab Emirates",
    city: "Dubai ",
    url: "https://www.altron.com/",
    description: "",
    point: {
      x: 0.39372370206488855,
      y: 0.2040347311208577,
      z: 0.264398077733685
    }
  },
  {
    uuid: "f4ada110-98a5-4a02-b88e-296f2860c32d",
    name: "Aleksandr Karpov",
    organization: "RnDoer OÜ",
    country: "Estonia",
    city: "Tallinn",
    url: "https://www.rndoer.com/",
    description: "",
    point: {
      x: 0.1105505130110396,
      y: 0.431384686928424,
      z: 0.2618648631194035
    }
  },
  {
    uuid: "f4ada110-98a5-4a02-b88e-296f2860c32d",
    name: "Paul Eident",
    organization: "Aslan Interactive",
    country: "United States",
    city: "Naperville",
    url: "",
    description: "",
    point: {
      x: -0.3838527484214058,
      y: 0.33420025809106324,
      z: 0.01155916145206816
    }
  },
  {
    uuid: "46bd5511-6c1e-445d-8a64-369cf386ebc7",
    name: "Dmitri Makeev",
    organization: "Er-telecom",
    country: "Russia",
    city: "Moscow",
    url: "https://www.ertelecom.ru/",
    description: "",
    point: {
      x: 0.19536440215857306,
      y: 0.4080743906842695,
      z: 0.23370371176298843
    }
  },
  {
    uuid: "4b515943-d6d8-405c-a6c6-fc421e15a642",
    name: "David Pither-Patterson",
    organization: "Ignite",
    country: "Australia",
    city: "Castlecrag",
    url: "",
    description: "",
    point: {
      x: 0.21494991798774882,
      y: -0.2903942751060197,
      z: -0.3702618864722011
    }
  },
  {
    uuid: "c7cfedd6-461f-499a-abc0-d21f29d4a272",
    name: "Steve Watts",
    organization: "Steve Watts",
    country: "Australia",
    city: "Fortitude Valley",
    url: "",
    description: "",
    point: {
      x: 0.2136273935309995,
      y: -0.24014107987909686,
      z: -0.4020698746053541
    }
  },
   {
    uuid: "dcfd17e5-c942-4a89-a7c1-fbd3c9689005",
    name: "Daniel McClure",
    organization: "Amplifier Agency",
    country: "Australia",
    city: "Melbourne",
    url: "",
    description: "",
    point: {
      x: 0.2380478319295408,
      y: -0.3196207777803945,
      z: -0.3373547009415692
    }
  },
  {
    uuid: "e2260066-fbe4-49d3-b7ad-c944bcc3c5d4",
    name: "Gregor Amon",
    organization: "MEM Services EOOD",
    country: "Bulgaria",
    city: "Sofia",
    url: "",
    description: "",
    point: {
      x: 0.14675298089021066,
      y: 0.3536040465135601,
      z: 0.3452501853343726
    }
  },
  {
    uuid: "a9f2ab65-89e5-46f6-b398-a052a9dbf730",
    name: "Murtada Shah",
    organization: "Serene Core Technologies",
    country: "Canada",
    city: "Edmonton",
    url: "",
    description: "",
    point: {
      x: -0.25543760132490567,
      y: 0.4271364260724754,
      z: -0.14899885610818944
    }
  },
  {
    uuid: "19cec506-7cf2-4dfe-af4a-2b932661fc5d",
    name: "Roy Hu",
    organization: "AO1 Marketing",
    country: "Canada",
    city: "Markham",
    url: "",
    description: "",
    point: {
      x: -0.3547842832105488,
      y: 0.36795835047070324,
      z: 0.04902622477074283
    }
  },
  {
    uuid: "80d6762f-9500-4bc3-a766-4d5553da815d",
    name: "Claire Duval",
    organization: "Markup Camp",
    country: "France",
    city: "Paris",
    url: "https://www.markupcamp.com/",
    description: "",
    point: {
      x: 0.015373660088645286,
      y: 0.38883678220379575,
      z: 0.33116158187398637
    }
  },
  {
    uuid: "2196cb1f-3035-4bec-8397-41af4128f1fd",
    name: "Eckhard Schneider",
    organization: "Decareto",
    country: "Germany",
    city: "Hamburg",
    url: "",
    description: "",
    point: {
      x: 0.0577495746444985,
      y: 0.4070457982279635,
      z: 0.30476024560549764
    }
  },
  {
    uuid: "ec7eb84d-7187-4b63-b1a6-d42fe68c3f07",
    name: "Megusta Enterprise",
    organization: "Megusta Enterprise",
    country: "Germany",
    city: "Munich",
    url: "",
    description: "",
    point: {
      x: 0.07955584656795232,
      y: 0.3877334723353137,
      z: 0.3265115523243566
    }
  },
  {
    uuid: "e667226a-87c9-40e4-ac12-ba3281273864",
    name: "Olaf Paetz",
    organization: "Gehaltsreporter",
    country: "Germany",
    city: "Uhldingen-Mühlhofen",
    url: "",
    description: "",
    point: {
      x: 0.035824254955093504,
      y: 0.3744312534515621,
      z: 0.3498176187292317
    }
  },
  {
    uuid: "47908f9f-a4e8-4b01-8c0d-fc09b09e6624",
    name: "Michael Li",
    organization: "MMDigi",
    country: "Hong Kong",
    city: "Wanchai",
    url: "",
    description: "",
    point: {
      x: 0.48338461033589375,
      y: 0.09650383542114732,
      z: -0.14324941141534311
    }
  },
  {
    uuid: "e8bceef0-aee8-4144-a2df-ef125d8c7da5",
    name: "Bhupesh Mittal",
    organization: "Myinvented",
    country: "India",
    city: "Jaipur",
    url: "",
    description: "",
    point: {
      x: 0.4414439596935989,
      y: 0.2316047880804609,
      z: 0.1131793905220394
    }
  },
  {
    uuid: "d660f720-4a68-463e-bc65-2b238cc6bcdc",
    name: "Ravi Sharma",
    organization: "Fusion Soft Multimedia",
    country: "India",
    city: "Jaipur",
    url: "",
    description: "",
    point: {
      x: 0.44213995382790006,
      y: 0.22337738826248865,
      z: 0.13377159691845503
    }
  },
  {
    uuid: "97a9c0bc-8a37-4f4b-9f7d-2fd8354aa551",
    name: "Guy Dubrovski",
    organization: "Artsetters",
    country: "Israel",
    city: "Tel Aviv",
    url: "",
    description: "",
    point: {
      x: 0.2581144137863676,
      y: 0.259181853848876,
      z: 0.36252146835529175
    }
  },
  {
    uuid: "a9e50a02-6beb-45ec-a466-ba762af98596",
    name: "Sharon Leon",
    organization: "Logic Mission Technologies",
    country: "Israel",
    city: "Mikhmoret",
    url: "",
    description: "",
    point: {
      x: 0.25906948301303023,
      y: 0.2794721140936064,
      z: 0.3506426880684475
    }
  },
  {
    uuid: "18150808-e75b-4cee-a2e3-ede3e1f52506",
    name: "Hideichi Saito",
    organization: "Hideichi Saito",
    country: "Japan",
    city: "Tokyo",
    url: "",
    description: "",
    point: {
      x: 0.269752734875375,
      y: 0.2999448161247588,
      z: -0.31545627873860305
    }
  },
  {
    uuid: "6486d980-732e-4f0e-bda1-793eb067a3e9",
    name: "Dmitry Degtyarev",
    organization: "7Dach",
    country: "Russia",
    city: "Moscow",
    url: "",
    description: "",
    point: {
      x: 0.1873467786443907,
      y: 0.40612586229053216,
      z: 0.25147283337595994
    }
  },
  // {
  //   uuid: "ad38af40-cf54-4b9e-a28b-057a7b35daf0",
  //   name: "Miracle McClain",
  //   organization: "Bizira",
  //   country: "United States",
  //   city: "West Hollywood",
  //   url: "",
  //   description: "",
  //   point: {
  //     x: -0.3775716495033118,
  //     y: 0.2936953797716242,
  //     z: -0.19363648344477763
  //   }
  // },
  // {
  //   uuid: "0ee7a82a-0890-4de1-9c77-15f3d0aae23c",
  //   name: "Zach Nolan",
  //   organization: "Blueprint",
  //   country: "United States",
  //   city: "Thousand Oaks",
  //   url: "",
  //   description: "",
  //   point: {
  //     x: -0.3653267459338665,
  //     y: 0.3110669208673553,
  //     z: -0.21584101687542434
  //   }
  // },
  // {
  //   uuid: "84cd9d6e-7314-4133-bcd9-1824e67699bb",
  //   name: "Paul Cross",
  //   organization: "Symbolic Strutures",
  //   country: "United States",
  //   city: "Sioux Falls",
  //   url: "",
  //   description: "",
  //   point: {
  //     x: -0.4003670804825228,
  //     y: 0.32691797222472124,
  //     z: -0.049737686973881
  //   }
  // },
  {
    uuid: "1de64b81-6446-4c6f-9bd8-493267413e63",
    name: "Akhil Aryan",
    organization: "ION labs",
    country: "United States",
    city: "San Francisco",
    url: "https://www.ionlabs.com/",
    description: "",
    point: {
      x: -0.3473123177412181,
      y: 0.33735897029019063,
      z: -0.19591522028076722
    }
  },
  {
    uuid: "9b79b7d1-7d5a-4cab-8421-c79eb04e20db",
    name: "Adrian Artiles",
    organization: "Kionin",
    country: "United States",
    city: "San Francisco",
    url: "https://kionin.com/",
    description: "",
    point: {
      x: -0.32846197495703633,
      y: 0.35254439796709697,
      z: -0.21826164890280267
    }
  },
  {
    uuid: "34dcdbd7-605c-49c6-bfc7-4a26068fbc21",
    name: "Abdul Masri",
    organization: "Makeen Technologies",
    country: "United States",
    city: "Redmond",
    url: "https://www.makeen.io/",
    description: "",
    point: {
      x: -0.291637541604884,
      y: 0.40199009510553996,
      z: -0.15958594805828127
    }
  },
  {
    uuid: "52dc9ea3-cdfc-4c53-827a-262a133a8fcf",
    name: "John Betancourt",
    organization: "Intelleges",
    country: "United States",
    city: "New York",
    url: "https://intelleges.webflow.io/",
    description: "",
    point: {
      x: -0.38431696089472606,
      y: 0.3137622030751175,
      z: 0.10609403428795328
    }
  },
  // {
  //   uuid: "46f2a864-aaa7-4051-93d1-82ef4e3baf31",
  //   name: "Robert Yang",
  //   organization: "Bobby Yang",
  //   country: "United States",
  //   city: "Morgan Hill",
  //   url: "",
  //   description: "",
  //   point: {
  //     x: -0.3498983748459439,
  //     y: 0.3303549266505223,
  //     z: -0.20047701226188877
  //   }
  // },
  {
    uuid: "7208e81b-ef34-4d89-adb2-d9ca42ebb00b",
    name: "Justin Khoo",
    organization: "Campaign Workhub",
    country: "United States",
    city: "Milpitas",
    url: "",
    description: "",
    point: {
      x: -0.36157479378261004,
      y: 0.3170728246781704,
      z: -0.21251647662034331
    }
  },
  {
    uuid: "00613fec-d874-44e1-901c-601f28a32f37",
    name: "Scott Anderson",
    organization: "ModMotive & InDesign",
    country: "United States",
    city: "Miami",
    url: "",
    description: "",
    point: {
      x: -0.44943334205744667,
      y: 0.23285187071610539,
      z: 0.07486927883270997
    }
  },
  {
    uuid: "e1376346-d0d5-4b66-a700-bb3a25ca8662",
    name: "Natalie Roth",
    organization: "Frontline Education",
    country: "United States",
    city: "Los Angeles",
    url: "https://www.frontlineeducation.com/",
    description: "",
    point: {
      x: -0.3909575154094118,
      y: 0.2965739072074524,
      z: -0.16820108165594005
    }
  },
  {
    uuid: "2c33d95d-30e2-4d1f-8fe3-6f51ee43b669",
    name: "Ben Clark",
    organization: "Talent Gurus",
    country: "United States",
    city: "Kingwood",
    url: "http://www.talentgurus.net",
    description: "",
    point: {
      x: -0.4433821465835648,
      y: 0.2576999140612958,
      z: -0.036322747802689674
    }
  },
  {
    uuid: "08e893ea-284f-4713-b954-3e9feb856fd2",
    name: "Lachie Thomas",
    organization: "Rever",
    country: "United States",
    city: "Eagle",
    url: "https://www.rever.co/",
    description: "",
    point: {
      x: -0.3656453116490006,
      y: 0.3477866429526246,
      z: -0.15426192343044537
    }
  },
  // {
  //   uuid: "f22dddcd-c792-479d-99d8-05f62ff61d0d",
  //   name: "Joe Joachim",
  //   organization: "Think Tank Group",
  //   country: "United States",
  //   city: "Detroit",
  //   url: "",
  //   description: "",
  //   point: {
  //      x: -0.3726962198943323,
  //     y: 0.3485269938925473,
  //     z: 0.04483316189599015
  //   }
  // },
  {
    uuid: "71e4b6f4-04b9-4147-9d21-7cd53b0e4da4",
    name: "Chondra Carston",
    organization: "Honcho",
    country: "United States",
    city: "Boulder",
    url: "",
    description: "",
    point: {
      x: -0.4143804495991214,
      y: 0.3097297363994749,
      z: -0.10698012160052552
    }
  },
  {
    uuid: "320fe41c-ef94-4617-99ec-0f348324024b",
    name: "Dimitris Gelos",
    organization: "Create Theory",
    country: "United States",
    city: "New York",
    url: "http://www.createtheory.com/",
    description: "",
    point: {
      x: -0.44258679913810695,
      y: 0.2694289149111997,
      z: 0.014722724785919206
    }
  },
  {
    uuid: "bfe0fa37-b719-4360-b21f-23cf3da23017",
    name: "Elijah Chancey",
    organization: "Mavaso",
    country: "United States",
    city: "Brunswick",
    url: "",
    description: "",
    point: {
      x: -0.3552314438441874,
      y: 0.354644473385298,
      z: 0.09116992526573843
    }
  },
  {
    uuid: "7af94b30-9281-48c2-9bf7-e143d63d320a",
    name: "Patrick Hogan",
    organization: "Tenfold",
    country: "United States",
    city: "Baltimore",
    url: "https://www.tenfold.com/",
    description: "",
    point: {
      x: -0.38547251463945453,
      y: 0.334886108719882,
      z: 0.06824532274632306
    }
  },
  {
    uuid: "79f1c7e9-888c-4ede-a47d-bdd4073997bc",
    name: "Ray Abram",
    organization: "nearbythis",
    country: "United States",
    city: "Atlanta",
    url: "http://www.nearbythis.com/",
    description: "",
    point: {
      x: -0.4230619967245115,
      y: 0.28568927901561486,
      z: 0.04344460091733039
    }
  },
  {
    uuid: "13edbac2-e20c-4692-afb1-73a87f561eee",
    name: "Jason Ho",
    organization: "D8 Solutions",
    country: "United Kingdom",
    city: "Surrey",
    url: "",
    description: "",
    point: {
      x: -0.009811560774299668,
      y: 0.40326799910027006,
      z: 0.32200502181278473
    }
  },
  {
    uuid: "be8a632a-8388-44fb-9e15-4d150f23c8e5",
    name: "Rickard Nygren",
    organization: "Grade",
    country: "Sweden",
    city: "Lund",
    url: "http://www.grade.com",
    description: "",
    point: {
      x: 0.06862415087673876,
      y: 0.4373059828063235,
      z: 0.26576876089191276
    }
  },
  {
    uuid: "3dbd6f0d-2c5c-4897-b282-41b2cf3d1826",
    name: "Thawab Alsubaie",
    organization: "Sha",
    country: "Saudi Arabia",
    city: "Riyadh",
    url: "",
    description: "",
    point: {
      x: 0.34348186125217245,
      y: 0.21224078103756333,
      z: 0.3188596938377392
    }
  },
  {
    uuid: "d8cb12e8-e1b0-4ead-9b82-e68995af60fd",
    name: "Skjalg Holther",
    organization: "Holther Web Services AS",
    country: "Norway",
    city: "Spydeberg",
    url: "",
    description: "",
    point: {
      x: 0.0504273630797197,
      y: 0.4397205223349827,
      z: 0.26968542643086624
    }
  },
  {
    uuid: "e56d0c43-509c-464e-85f4-25df44b0e8e4",
    name: "Wynne Pirini",
    organization: "Shockalotti",
    country: "New Zealand",
    city: "Wellington",
    url: "",
    description: "",
    point: {
      x: 0.04396031878402569,
      y: -0.34753389910751137,
      z: -0.3826862192410235
    }
  },
  {
    uuid: "",
    name: "",
    organization: "",
    country: "",
    city: "",
    url: "",
    description: "",
    point: {
      x: 0.0,
      y: 0.0,
      z: 0.0
    }
  },
]
