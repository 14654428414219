export const translates = {
  notifications: {
    contactUsForm: {
      success: {
        title: `Your application has been sent`,
        description: `We will contact you shortly. Thank you for your request.`
      },
      error: {
        title: `Unknown error`,
        description: `Please contact us via email at hello@intspirit.com`
      }
    },
    vacancyForm: {
      success: {
        title: `Your application has been sent`,
        description: `We will contact you shortly. Thank you for your request.`
      },
      error: {
        title: `Unknown error`,
        description: `Please contact us via email at hello@intspirit.com`
      }
    }
  }
}
