export const CookieTypes = {
  'Token': 'Token',
  'CookiesAccepted': 'cookies_accepted',
  'SelectedLocale': 'selected_locale'
}

class CookieService {
  get(token) {
    let name = token + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  set(token, value, exdays) {
    let cookie = `${token}=${value};`;

    if (exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      cookie += `expires=${d.toUTCString()};`;
    }

    document.cookie = `${cookie};path=/`;
  }

  delete(token) {
    document.cookie = `${token}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  check(token) {
    let value = this.get(token);

    return !!value;
  }
}

export const cookieService = new CookieService();
