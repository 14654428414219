import { scrollTrigger, TriggerEvents } from './scroll-trigger';

export function setTriggerOnGreySections(options) {
  const greySections = document.getElementsByClassName('grey-section');
  const backgroundGrey = document.getElementById('background-grey');

  for (let i = 0; i < greySections.length; i++) {
    setupBgAnimation(greySections[i], options);
  }

  function setupBgAnimation(section, options = {}) {
    return scrollTrigger.registerElement(section, {
      start: options.start || 'top bottom',
      end: options.end || 'top top',
      progress: true
    })
    .subscribe(handleBgScrollProgress);
  }

  function handleBgScrollProgress(data) {
    if (data.event === TriggerEvents.PROGRESS) {
      requestAnimationFrame(() => {
        backgroundGrey.style.opacity = `${data.progress}`;
      });
    }
  }
}
